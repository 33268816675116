<template>
  <div class="changeBackground">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">更换相册封面</div>
      <div class="rightBtn">
        <div class="save" @click="save">保存</div>
      </div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :error="error"
        :isNoData="isNoData"
        disabled
      >
        <div class="avatarList">
          <div class="avatarItem" :class="{ activeItem: selectId == i.id }" v-for="i in list" :key="i.id" @click="selectImg(i)">
            <ImgDecypt class="userAvatar" :src="i.resource" />
            <div class="checked" v-if="selectId == i.id">
              <svg-icon class="checkedIcon" iconClass="bgSelected"></svg-icon>
            </div>
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { Toast } from 'vant';
import { getUserResource } from '@/api/user';
import { modifyPersonInfo } from '@/api/user';
export default {
  name: 'changeBackground',
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      selectId: '', // 选中头像ID
      currentAvatar: '', // 当前头像
    };
  },
  created() {
    this.currentAvatar = this.$store.getters.userInfo.portrait;
    this.getList();
  },
  methods: {
    // 查询列表
    async getList() {
      let req = {
        type: 'bg',
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      let res = await this.$Api(getUserResource, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let arr = res.data.list ? res.data.list : [];
        this.list = this.list.concat(arr);
        if (this.list.length <= 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
          return;
        }
      } else {
        this.error = true;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.list = [];
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList();
    },
    // 选择头像
    selectImg(item) {
      this.selectId = item.id;
      this.currentAvatar = item.resource;
    },
    // 保存
    async save() {
      if (!this.currentAvatar) {
        Toast('请选择头像');
      }
      let req = {
        background: [this.currentAvatar],
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(modifyPersonInfo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          Toast('修改成功');
          // this.$router.go(-1);
        } else {
          Toast(res.tip || '修改失败，请稍后再试');
        }
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeBackground {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      .save {
        height: 30px;
        width: 60px;
        background-color: rgb(255, 103, 143);
        text-align: center;
        line-height: 30px;
        border-radius: 30px;
        color: #fff;
        margin-left: 25px;
      }
    }
  }

  .main {
    padding: 10px 12px 0;
    height: calc(100% - 44px);
    .avatarList {
      width: 100%;
      .avatarItem {
        height: 104px;
        margin-bottom: 10px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        .checked {
          width: 45px;
          height: 36px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 100;
          .checkedIcon {
            width: 45px;
            height: 36px;
          }
        }
      }

      // .avatarItem {
      //   width: 95px;
      //   height: 95px;
      //   border-radius: 4px;
      //   overflow: hidden;
      //   // background: #fff;
      //   position: relative;
      // }

      // .activeItem {
      //   width: 93px;
      //   height: 93px;
      //   border: 1px solid #eeae19;
      //   position: relative;
      // }

      // .checked {
      //   width: 20px;
      //   height: 20px;
      //   background: #eeae19;
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      //   z-index: 100;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   border-radius: 7px 0 0 0;

      //   .checkedIcon {
      //     width: 10px;
      //     height: 10px;
      //   }
      // }
    }
  }
}
</style>
